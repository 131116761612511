.steps {
  position: relative;
  margin: 0 auto;
  &::before {
    content: ' ';
    border-top: 1px solid #62583d;

    position: absolute;
    top: 190px;
    width: 80%;
    left: 10%;
    right: 0;
  }
}

.step {
  border-radius: 50%;
  width: 100px;
  height: 100px;

  background: radial-gradient(70% 70% at 50% 50%, #62583d 0%, #0c0b0b 100%);
  border: 2px solid #4f4735;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3), 0px 20px 60px rgba(0, 0, 0, 0.42);
}

.stepNo {
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  opacity: 0.05;
}

.stepIcon {
  width: 38px;
  height: 38px;
}

.stepTitle {
  color: #87878d;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

[display-type='2'] {
  &::before {
    top: 60px;
  }

  .step {
    width: 40px;
    height: 40px;
  }

  .stepIcon {
    width: 16px;
    height: 16px;
  }

  .stepTitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .steps::before {
    border-top: none;
    border-left: 1px solid #62583d;
    top: 10%;
    height: 80%;
    left: 45%;
    right: unset;
  }

  .step {
    width: 60px;
    height: 60px;
  }

  .stepIcon {
    width: 24px;
    height: 24px;
  }
}
