@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Segoe UI Variable';
  src: url('assets/fonts/Segoe-UI-Variable-Static-Display-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('assets/fonts/Segoe-UI-Variable-Static-Display-Semibold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('assets/fonts/Segoe-UI-Variable-Static-Display.ttf') format('truetype');
  font-weight: 400;
}

body {
  font-family: 'Segoe UI Variable', sans-serif !important;
  font-size: 1rem;
  background-color: #181c1e;
}

@import '_ulti.scss';